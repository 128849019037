.loader-store-products {
  display: none;
  margin:auto;
  position: absolute;
  left:0;
  right:0;
  bottom: 95px;
  border: 6px solid #F28C28;
  border-radius: 50%;
  border-top: 6px solid rgba(0,0,0,0.15);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1.5s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;
  z-index: 3;
}

.loading-txt-store-products {
  display: none;
  text-align: center;
  justify-content: center;
  font-family: "Open Sans";
  color: black;
  margin:auto;
  position: absolute;
  left:0;
  right:0;
  bottom: 67px;
  font-size: 14px;
  font-weight: 600;
  z-index: 3;
}

.responsive2 {
  margin: 6px 0;
  float: left;
  width: 24.99999%;
}

@media only screen and (max-width: 1450px) {
  .responsive2 {
    width: 33.33333%;
    margin: 6px 0;
  }
}

@media only screen and (max-width: 1000px) {
  .responsive2 {
    width: 49.99999%;
    margin: 6px 0;
  }
}

@media only screen and (max-width: 600px) {
  .responsive2 {
    margin: 0 auto;
    width: 100%;
  }
}

.gallery2 {
  margin-top: 0px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 5px;
  padding: 5px;
  padding-bottom: 20px;
}

.product-link-cardview-full {
  color: black;
  text-decoration: none;
}
.product-link-cardview-full:hover {
  color: black
}

.product-link-cardview-full:focus .gallery2 {
  filter: opacity(0.7) drop-shadow(0 0 0 rgb(0, 0, 0, 0.09));
  background-color: rgb(0, 0, 0, 0.09);
}

.product-img-cardview-full {
  height: auto;
  border-radius: 5px;
  width: 100%;
}

.product-cardview-full-grid-col-container {
  display: grid;
  grid-template-columns: 20% 80%;
  
}

.product-grid-cardview-full-col-1 {
  margin-top: 12px;
  text-align: center;
  line-height: 1.3;
  height: 120px; 
}

.product-cardview-full-profile-picture {
  margin-top: 10px;
  height: 40px;
  width: 40px;
  margin: 0 auto;
  border-radius: 50%;
  position: relative;
  padding-right: 2px;
}
@media only screen and (max-width: 450px) {
  .product-cardview-full-profile-picture {
    height: 30px;
    width: 30px;
  }
}

.product-grid-cardview-full-col-2 {
  margin-top: 12px;
  text-align: left;
  line-height: 1.3;
  height: 120px; 
}
@media only screen and (max-width: 300px) {
  .product-grid-cardview-full-col-2 {
    height: 140px;
  }
}

.product-name-grid-cardview-full-col-2 {
  font-size: 14px;
  font-weight: 500;
  font-family: "Open Sans";
  color: black;
}
@media only screen and (max-width: 450px) {
  .product-name-grid-cardview-full-col-2 {
    font-size: 13px;
  }
}

.store-name-grid-cardview-full-col-2 {
  font-size: 13px;
  font-weight: 400;
  font-family: "Open Sans";
  color: rgb(98, 98, 98);
  margin-top: 4px;
}
@media only screen and (max-width: 450px) {
  .store-name-grid-cardview-full-col-2 {
    font-size: 12px;
  }
}

.product-location-grid-cardview-full-col-2 {
  font-size: 16px;
  font-weight: 400;
  font-family: "Open Sans";
  color: rgb(98, 98, 98);
  margin-top: 2px;
}
@media only screen and (max-width: 450px) {
  .product-location-grid-cardview-full-col-2 {
    font-size: 12px;
  }
}

.product-elapsed-upload-time-grid-cardview-full-col-2 {
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans";
  margin-top: 4px;
}
@media only screen and (max-width: 450px) {
  .product-elapsed-upload-time-grid-cardview-full-col-2 {
    font-size: 10px;
  }
}

.product-price-grid-cardview-full-col-2 {
  font-size: 19px;
  color: rgb(72, 72, 72);
  margin-top: 2px;
  font-weight: 500;
  font-family: "Open Sans";
}

/* CSS remains the same */
.responsive-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  border-radius: 5px;
  background-color: #eee; /* Placeholder color (optional) */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
} 

.responsive-container::before {
  content: "";
  display: block;
  padding-top: 100%; /* Aspect ratio 1:1 */
}